.main {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: right;
  flex-direction: column;
  row-gap: 10px;
}

.h1SecondStyle {
  margin: 10px;
  font-size: 40px;
  color: #4e73df;
}

.hANewStyle {
  margin-block: 0.5em;
  font-size: 40px;
  color: #e74a3b;
}

.hBNewStyle {
  margin-block: 0.5em;
  font-size: 40px;
  color: #067330;
}

.h1NewStyle {
    margin-block: 0.5em;
    font-size: 40px;
    color: #4e73df;
}

.h3NewStyle {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  color: #4e73df;
}

.h5NewStyle {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  padding-left: 10px;
  color: #c7c006;
}

.h6NewStyle {
  margin-block: 0.5em;
  padding-left: 0.5em;
  font-size: 24px;
  color: #4e73df;
}

.h14NewStyle {
  padding-top: 0.5em; 
  padding-left: 0em;
  padding-right: 0.5em; 
  font-size: 24px;
  color: #4e73df;
}

.h14NewStyleDark {
  padding-top: 0.5em; 
  padding-left: 0em;
  padding-right: 0.5em;
  font-size: 24px;
  color: #858796;
}

.h7NewStyle {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  color: #067330; 
}

.h8NewStyle {
  font-size: 14px;
  font-family: "Roboto","Helvetica","Arial";
  color: #067330;
}

.h9NewStyle {
  margin-block: 0.5em;
  font-size: 30px; 
  color: #067330;
}

.h11NewStyle {
  font-size: 18px;
  padding-left: 0px;
  font-family: "Roboto","Helvetica","Arial";
  color: #067330;
}
.h3NewStyleBold {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  font-weight: bold;
  color: #4e73df;
}

.h3NewStyleDark {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  color: #858796;
}

  .h2NewStyle {
    margin-block: 0.5em;
    font-size: 30px;
    color: #4e73df;
  }
  
  .h2NewStyleDark {
    margin-block: 0.5em;
    font-size: 30px;
    color: #858796;
    text-decoration: line-through;
  }

  .h2Enabled {
    margin-block: 0.5em;
    font-size: 20px;
    color: #4e73df;
  }
  
  .h2Disabled {
    margin-block: 0.5em;
    font-size: 20px;
    color: #858796;
    text-decoration: line-through;
  }


.h7SacaleStyle {
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial";
  color: #ffffff; 
  background-color: #4e73df;
  font-weight: bold;
  align-content: center;
  text-align: center;
  padding: 2px 2px;
}

  .searchStyle {
    width: 100%;
    height: 1000;
  }
  
  .searchStyleSmall {
    width: 40%;
    height: 1000;
  }
  
  #myDIV {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  
  .buttonCreate {
    margin-block: 0.5em;
    padding: 8px 40px;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 150px;
    height: 50px;
  }
    
  .buttonCreate:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonCreate:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }
  
  .buttonNormalized {
    margin-block: 0.5em;
    padding: 8px 40px;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 200px;
    height: 50px;
  }
    
  .buttonNormalized:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonNormalized:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonFinish {
    margin-block: 0.5em;
    padding: 8px 4px;
    border-radius: 40px;
    background-color: #ffffff;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color:#4e73df ;
    font-weight: 500;
    outline: rgba(34, 117, 240, 0.5);    
    cursor: pointer;
    width : 270px;
    height: 50px;
  }
   
  .buttonFinish:hover {
    background-color: #4e73df; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
   
  .buttonFinish:active {
   background-color: #4e73df; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }
  
  .buttonBack {
    padding: 8px 40px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #a7afc7;
    border-color : #595d5b ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 150px;
    height: 50px;
  }
    
  .buttonBack:hover {
    background-color: #6d7899; 
    border-color: #595d5b;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonBack:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonFilter {
    padding: 8px 20px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 12px;
    align-items: left;
    text-align: left;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : flex;
    height: 40px;
  }
    
  .buttonFilter:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonFilter:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonExcel {
    padding: 8px 40px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #077e35;
    border-color : #077e35 ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 500;
    outline: rgba(186, 250, 223, 0.5); 
    cursor: pointer;
    width : flex;
    height: 46px;
  }
    
  .buttonExcel:hover {
    background-color: #055c26; 
    border-color: #004019;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonExcel:active {
    background-color: #004019; 
    border-color: rgba(60, 215, 125, 0.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(17, 198, 144, 0.516); 
  }

  .buttonVicasoft {
    padding: 8px 30px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #cb9331;
    border-color : #91610d ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 15px;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 500;
    outline: rgba(176, 105, 39, 0.5); 
    cursor: pointer;
    width : flex;
    height: 46px;
  }
    
  .buttonVicasoft:hover {
    background-color: #91610d; 
    border-color: #3f2904;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonVicasoft:active {
    background-color: #3f2904; 
    border-color: rgba(202, 159, 15, 0.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(191, 155, 11, 0.516); 
  }

  .buttonPDF {
    padding: 8px 30px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #e74a3b;
    border-color : #e74a3b ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 500;
    outline: rgba(226, 41, 41, 0.5); 
    cursor: pointer;
    width : flex;
    height: 46px;
  }
    
  .buttonPDF:hover {
    background-color: #aa3025; 
    border-color: #aa3025;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonPDF:active {
    background-color: #8c150a; 
    border-color: rgba(70, 3, 3, 0.5); 
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(177, 3, 3, 0.516); 
  }
 
  .buttonLopdAcepted {
    padding: 8px 20px;
    margin-left: 6em;
    border-radius: 40px;
    background-color: #ffffff;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 12px;
    align-items: center;
    text-align: center;
    color:#4e73df ;
    font-weight: bold;
    outline: rgba(34, 117, 240, 0.5);   
    cursor: pointer;
    width : flex;
    height: 40px;
  }

  .buttonLopdAcepted:hover {
    background-color: #4e73df; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonLopdAcepted:active {
    background-color: #4e73df; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonLopdSend {
    padding: 8px 20px;
    margin-left: 6em;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 12px;
    align-items: center;
    text-align: center;
    color: #ffffff ;
    font-weight: bold;
    outline: rgba(105,136,228,.5);     
    cursor: pointer;
    width : flex;
    height: 40px;
  }

  .buttonLopdSend:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonLopdSend:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }
    
.buttonCreateUserAccount {
    margin-block: 0.5em;
    padding: 8px 4px;
    border-radius: 40px;
    background-color: #ffffff;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color:#4e73df ;
    font-weight: 500;
    outline: rgba(34, 117, 240, 0.5);    
    cursor: pointer;
    width : 150px;
    height: 50px;
  }
    
  .buttonCreateUserAccount:hover {
    background-color: #4e73df; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonCreateUserAccount:active {
    background-color: #4e73df; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }
  
  .buttonDeleteUserAccount {
    padding: 8px 4px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: white;
    border-color : #e74a3b ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: #e74a3b;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 150px;
    height: 50px;
  }
    
  .buttonDeleteUserAccount:hover {
    background-color: #e74a3b; 
    border-color: #ea6b54;
    box-shadow: none;
    color: white;
  }
  
  .buttonDeleteUserAccount:active {
    background-color: #e74a3b; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(248, 171, 171, 0.471);
  }

  .buttonDisabledUserAccount {
    padding: 8px 4px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #858796;
    border-color : #595d5b ;
    text-decoration: line-through;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: #ffffff ;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 150px;
    height: 50px;
  }


  .buttonDeleteRow {
    margin-block: 0.5em;
    padding: 8px 20px;
    background-color: #e74a3b;
    border-color : #e74a3b ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    width : 120px;
    height: 40px;
  }
    
  .buttonDeleteRow:hover {
    background-color: #74221a; 
    border-color: #74221a;
    box-shadow: none;
    color: white;
  }
  
  .buttonDeleteRow:active {
    background-color: #e74a3b; 
    background-color: #e74a3b;
    box-shadow: none;
    color: white;
  }

  .buttonSendShared {
    margin-block: 0.1em;
    padding: 4px;
    background-color: #067330;
    border-color : #067330 ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(186, 250, 223, 0.5);    
    cursor: pointer;
    width : 440px;
    height: 50px;
  }
    
  .buttonSendShared:hover {
    background-color: #055c26; 
    border-color: #004019;
    box-shadow: none;
    color: white;
  }
  
  .buttonSendShared:active {
    background-color: #004019; 
    border-color: rgba(60, 215, 125, 0.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(17, 198, 144, 0.516);
  }

  .buttonEnabledArticle {
    padding: 8px 40px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);   
    cursor: pointer;
    width : 200px;
    height: 50px;
  }
    
  .buttonEnabledArticle:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonEnabledArticle:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonDisabledArticle {
    padding: 8px 40px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #a7afc7;
    border-color : #595d5b ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 16px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);     
    cursor: pointer;
    width : 200px;
    height: 50px;
  }
    
  .buttonDisabledArticle:hover {
    background-color: #6d7899; 
    border-color: #595d5b;
    box-shadow: none;
    color: white;
  }
  
  .buttonDisabledArticle:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .buttonFinishPurchase {
    margin-block: 0.5em;
    padding: 8px 4px;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 14px;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : 250px;
    height: 50px;
  }
    
  .buttonFinishPurchase:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  }
  
  .buttonFinishPurchase:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .rowpdf {
    height: 100%; /* Asegura que las filas ocupen el espacio completo */
 
  }
  
  .cardpdf {
    height: 100%; /* Asegura que la tarjeta ocupe todo el espacio */
    display: flex;
    flex-direction: column;
  }
  
  .cardpdf-body {
    height: 100%; /* Mantén la altura completa en el cuerpo */
    display: flex;
    flex-direction: column;
  }
  
  .formgrouppdf {
    height: 100%; /* Mantén la altura completa dentro del contenedor */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
    border: none; /* Sin bordes */
  }

  .buttonFilterHigh {
    padding: 8px 30px;
    margin-block: 0.5em;
    border-radius: 40px;
    background-color: #4e73df;
    border-color : #4e73df ;
    line-height: 1.5;
    box-shadow: none ;
    font-size: 14px;
    align-items: left;
    text-align: left;
    color: white;
    font-weight: 500;
    outline: rgba(105,136,228,.5);    
    cursor: pointer;
    width : flex;
    height: 46px;
  }
    
  .buttonFilterHigh:hover {
    background-color: #2653d4; 
    border-color: #0062cc;
    box-shadow: none;
    color: white;
  
  }
  
  .buttonFilterHigh:active {
    background-color: #0062cc; 
    border-color: rgba(105,136,228,.5);
    box-shadow: none;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  }

  .h6NewStyleHigh {
    margin-block: 0.5em;
    padding-left: 0.5em;
    font-size: 28px;
    color: #4e73df;
  }

  .custom-btn {
    font-size: 24px; /* Cambia al tamaño deseado */
  }

  .custominsert-btn {
    font-size: 22px; /* Cambia al tamaño deseado */
  }

  .dropdown-class {
    max-height: 200px;
    overflow-y: auto;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  

  
